
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';

export default defineComponent({
	name: 'user-details',
	components: {},
	setup() {
		const store = useStore();
		const route = useRoute();
		const formRef = ref<null | HTMLFormElement>(null);
		const loading = ref<boolean>(false);

		const isEdit = route.params.id;

		const formData = ref({
			id: 0,
			level: 0,
			label: '',
			customerId: 0
		});

		const rules = ref({
			label: [
				{
					required: true,
					message: 'User Level Title is required',
					trigger: 'change'
				}
			],
			level: [
				{
					required: true,
					message: 'User level is required',
					trigger: 'change'
				}
			]
		});

		const getuserDetails = () => {
			ApiService.get(`UserLevel/${route.params.id}`)
				.then(({ data }) => {
					formData.value = { ...formData.value, ...data };
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} User Level`, [
				'User Level'
			]);
			if (isEdit) {
				getuserDetails();
			}
		});

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;

					const data: any = {
						...formData.value,
						customerId: selectedCustomer.value
					};
					Object.keys(data).forEach(key => {
						if (data[key] === null || data[key] === '') {
							delete data[key];
						}
					});

					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(`UserLevel/${route.params?.id}`, data);
					} else {
						delete data['id'];
						serviceType = ApiService.post(`UserLevel`, data);
					}

					serviceType
						.then(({ data }) => {
							// console.log('create user response', data);
							loading.value = false;
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (isEdit) {
									formData.value = { ...formData.value, ...data };
								} else {
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();
								}
							});
						})
						.catch(({ response }) => {
							console.log('create User level error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			rules,
			isEdit,
			submit,
			formRef,
			loading,
			formData
		};
	}
});
